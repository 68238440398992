import React, { useCallback, useMemo } from "react";
import ReactSelect from "react-select";
import ReactSelectAsync from "react-select/async";
import clsx from "clsx";

import Container from "../Select3/Container";
import DropdownIndicator from "../Select3/DropdownIndicator";
import SingleValue from "../Select3/SingleValue";
import MenuList from "../Select3/MenuList";
import { dimensions, palette } from "@/components/Select3/constants";

type Props = Parameters<ReactSelectAsync>[0] & {
  error?: string;
  height?: string;
  smallFontSize?: boolean;
};

const Select: React.FC<Props> = React.forwardRef(
  ({ placeholder, options, loadOptions, smallFontSize, isSearchable, components, error, height, ...props }, ref) => {
    const theme = useCallback((t: any) => {
      return {
        ...t,
        borderRadius: dimensions.borderRadius,
        spacing: {
          ...t.spacing,
          controlHeight: dimensions.height,
        },
        colors: {
          ...t.colors,
          primary: "#555F6D",
          primary25: palette.opacity25,
          primary75: palette.opacity75,
          neutral80: "#E5E9F0",
        },
      };
    }, []);

    const customStyles = useMemo(
      () => ({
        container: (provided: any, state: any) => ({
          ...provided,
          borderRadius: dimensions.containerBorderRadius,
          backgroundColor: palette.bg,
          cursor: state.isDisabled ? "not-allowed" : "initial",
        }),
        control: (provided: any, state: any) => ({
          ...provided,
          borderColor: state.isFocused ? "#555F6D" : state.isSelected ? "#9EA8B3" : "#D0D6E0",
          maxHeight: dimensions.height,
          backgroundColor: palette.bg,
          outline: "none",
          boxShadow: "none",
          borderWidth: "0.5px",
          cursor: state.isDisabled ? "not-allowed!important" : "initial",
          opacity: state.isDisabled ? "30%" : "initial",
        }),
        indicatorsContainer: (provided: any) => ({
          ...provided,
          margin: dimensions.indicatorMargin,
        }),
        valueContainer: (provided: any) => ({
          ...provided,
          padding: dimensions.valueContainerPadding,
          marginTop: dimensions.valueContainerMarginTop,
          marginLeft: dimensions.valueContainerMarginLeft,
        }),
        input: (provided: any) => ({
          ...provided,
          margin: dimensions.inputMargin,
          fontWeight: dimensions.inputFontWeight,
          fontSize: dimensions.inputFontSize,
        }),
        menu: (provided: any) => ({
          ...provided,
          zIndex: "1000",
        }),
      }),
      [error],
    );

    return (
      // @ts-ignore
      <div className="group relative">
        <label
          className={clsx(
            "pointer-events-none absolute left-4 z-[1] max-w-[calc(100%-50px)] -translate-y-1/2 align-middle text-textBodySemi text-label transition-all",
            "overflow-hidden text-ellipsis whitespace-nowrap",
            props.inputValue || !!props.value ? "!top-0 !mt-4 !text-textSmall" : "top-1/2 opacity-70",
            "group-focus-within:top-0 group-focus-within:mt-4 group-focus-within:!text-textSmall group-focus-within:text-secondaryText",
            props.isDisabled && "opacity-30",
            smallFontSize && "text-textSmallSemi",
          )}
        >
          {placeholder}
        </label>
        {loadOptions ? (
          <ReactSelectAsync
            ref={ref}
            placeholder=""
            theme={theme}
            styles={customStyles}
            loadOptions={loadOptions}
            components={{
              ...components,
              SelectContainer: Container,
              IndicatorSeparator: null,
              DropdownIndicator,
              SingleValue,
              MenuList,
              Option,
            }}
            {...props}
          />
        ) : (
          <ReactSelect
            ref={ref}
            placeholder=""
            theme={theme}
            styles={customStyles}
            components={{
              ...components,
              SelectContainer: Container,
              IndicatorSeparator: null,
              DropdownIndicator,
              SingleValue,
              MenuList,
              Option,
            }}
            options={options}
            isSearchable={isSearchable || false}
            {...props}
          />
        )}
        {error && (
          <label className={clsx("text-2xs absolute -bottom-0.5 right-0 translate-y-full leading-3 text-error")}>
            {error}
          </label>
        )}
      </div>
    );
  },
);

export default Select;

const Option = (props: any) => {
  return (
    <span
      ref={props.innerRef}
      {...props.innerProps}
      className={clsx(
        "flex cursor-pointer items-center bg-white px-4 py-3 text-textBody hover:bg-bgDisabled active:bg-bg",
        {
          "text-secondaryText/50": props.isSelected,
          "text-primaryText": !props.isSelected,
        },
      )}
    >
      {props.selectProps.getOptionLabel(props.data)}
    </span>
  );
};
