import React, { useCallback, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { createFilter } from "react-select";
import clsx from "clsx";
import Select from "@/components/Select";
import Autocomplete from "@/components/Autocomplete2/StyledInput";
import FieldSet from "@/components/FieldSet2";
import CalculatorIcon from "@/components/icons/CalculatorIcon";

import useUser from "@/data/hooks/useUser";
import {
  containerTypes as containerTypesConfig,
  DeliveryType,
  EMPTY_CONTAINER,
  initialDashboardPage,
  LocationType,
} from "@/data/constants";
import { orders, suggestions } from "@/services";
import { numberWithSpaces, terminalFilterConfig } from "@/data/utils";
import {
  CALCULATE_TARIFF_BUTTON,
  CONTAINER_HEIGHT_SELECT_WRAPPER_TESTID,
  CONTAINER_TYPE_SELECT_WRAPPER_TESTID,
  CONTAINER_WEIGHT_SELECT_WRAPPER_TESTID,
  DELIVERY_CITY_SELECT_WRAPPER_TESTID,
  DELIVERY_TERMINAL_SELECT_WRAPPER_TESTID,
  DELIVERY_TYPE_SELECT_WRAPPER_TESTID,
  PICKUP_CITY_SELECT_WRAPPER_TESTID,
  PICKUP_TERMINAL_SELECT_WRAPPER_TESTID,
  TARIFF_CONTAINER_TESTID,
  WAREHOUSE_ADDRESS_WRAPPER_TESTID,
} from "./test-ids";
import { AppError, DirectoryRecord, TerminalEntity } from "@/domain";
import {
  useActiveContainerClasses,
  useActiveContainerHeights,
  useActiveContainerTypes,
  useActiveDeliveryTypes,
  useActiveLocations,
  useOrderConfig,
} from "@/data/hooks/orderHooks";
import LandingSectionTitle from "src/landing/components/SectionTitle";
import Section from "@/components/Section2";
import IconButton from "@/components/Button2/IconButton";
import { WeightInput } from "@/components/MaskedInput2";
import { useRouter } from "next/router";
import Arrow from "@/components/icons/Arrow";
import TerminalOption from "@/components/Select/options/Terminal";

interface FormState {
  cargos: { weight?: string; containerHeight: DirectoryRecord | null }[];
  deliveryType: DirectoryRecord | null;
  containerType: DirectoryRecord | null;
  containerClass: DirectoryRecord | null;
  pickupCity: DirectoryRecord | null;
  pickupTerminal: TerminalEntity | null;
  deliveryCity: DirectoryRecord | null;
  deliveryTerminal: TerminalEntity | null;
  warehouseAddress: DirectoryRecord | null;
  howToCarry: DirectoryRecord | null;
}

const Calculator = () => {
  const { authenticated } = useUser();
  const router = useRouter();

  const [tariff, setTariff] = useState<number | null>(null);

  const locations = useActiveLocations();
  const deliveryTypes = useActiveDeliveryTypes();
  const containerTypes = useActiveContainerTypes();
  const containerHeights = useActiveContainerHeights();
  const containerClasses = useActiveContainerClasses();

  const { handleSubmit, control, reset, resetField, setValue, watch } = useForm<FormState>({
    defaultValues: {
      cargos: [{ weight: "", containerHeight: null }],
      containerType: null,
      pickupCity: null,
      pickupTerminal: null,
      deliveryCity: null,
      deliveryTerminal: null,
      warehouseAddress: null,
      howToCarry: null,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "cargos",
  });
  const mutation = useMutation(orders.calculateTariffPublic, {
    onSuccess: (data) => {
      setTariff(data);
    },
    onError: (err: AppError) => {},
  });

  const deliveryType = watch("deliveryType");
  const containerType = watch("containerType");
  const pickupCity = watch("pickupCity");
  const deliveryCity = watch("deliveryCity");

  const onFormSubmit = useCallback(
    (data: FormState) => {
      mutation.mutate({
        cargos: data.cargos.map((i) => ({
          cargoWeight: i.weight ? parseInt(i.weight.replace(/\s/g, "")) : undefined,
          containerHeight: i.containerHeight?.value,
        })),
        deliveryType: data.deliveryType!.value as DeliveryType,
        containerType: data.containerType!.value,
        containerClass: data.containerClass?.value,
        howToCarry: data.howToCarry?.value,
        pickupCity: data.pickupCity!.value as LocationType,
        pickupTerminal: data.pickupTerminal!.value,
        deliveryCity: data.deliveryCity!.value as LocationType,
        deliveryTerminal: data.deliveryTerminal!.value,
        warehouseAddress: data.warehouseAddress?.value,
      });
    },
    [mutation],
  );

  const isEmptyContainer = deliveryType?.value === EMPTY_CONTAINER;
  const isContainerHeightRequired =
    [containerTypesConfig["20tank"], containerTypesConfig["40tank"]].indexOf(containerType?.value!) === -1;

  const {
    isContainerClassRequired,
    isWarehouseAddressRequired,
    pickupTerminals,
    deliveryTerminals,
    howToCarryOptions,
  } = useOrderConfig({
    deliveryType: deliveryType?.value,
    containerType: containerType?.value,
    pickupCity: pickupCity?.value,
    deliveryCity: deliveryCity?.value,
  });
  const initialDeliveryType = deliveryTypes[0] ?? null;

  useEffect(() => {
    resetField("deliveryType", { defaultValue: initialDeliveryType });
  }, [initialDeliveryType]);
  // reset form on delivery type change
  useEffect(() => {
    // deliveryType is null on first render
    if (!deliveryType) return;
    reset();
    //
    setValue("deliveryType", deliveryType);
    setTariff(() => null);
  }, [deliveryType?.value]);
  // reset pickup terminal on pickup city change
  useEffect(() => {
    resetField("pickupTerminal");
  }, [pickupCity]);
  // reset delivery terminal on delivery city change
  useEffect(() => {
    resetField("deliveryTerminal");
  }, [deliveryCity]);
  // remove second cargo on container type change if needed
  useEffect(() => {
    if (containerType?.value === containerTypesConfig["2x20f"]) {
      append({ weight: undefined, containerHeight: undefined });
    } else {
      remove(1);
    }
  }, [containerType]);

  return (
    <Section data-testid="calculator-section">
      <div className="mb-10 flex w-full max-w-xxl flex-row gap-8 rounded-[40px] py-8 tablet:mb-12 tablet:border tablet:border-divider tablet:py-0 desktop:my-15">
        <div className="flex w-full flex-col gap-6 tablet:p-8 desktop:gap-10 desktop:p-14 desktop:pr-0">
          <LandingSectionTitle>Расчет тарифа</LandingSectionTitle>
          <form onSubmit={handleSubmit(onFormSubmit)} className="flex flex-col gap-6 desktop:gap-10">
            <FieldSet legend="Общая информация">
              <div id="information-section-container">
                <div className="mt-6 grid grid-cols-1 gap-6 tablet:grid-cols-2">
                  <div data-testid={DELIVERY_TYPE_SELECT_WRAPPER_TESTID}>
                    <Controller
                      control={control}
                      name="deliveryType"
                      render={({ field, fieldState }) => (
                        <Select
                          {...field}
                          instanceId="delivery-type"
                          options={deliveryTypes}
                          placeholder="Вид доставки *"
                          error={fieldState.error?.message}
                          isSearchable={false}
                        />
                      )}
                      rules={{
                        required: { value: true, message: "Обязательное поле" },
                      }}
                    />
                  </div>
                  <div data-testid={CONTAINER_TYPE_SELECT_WRAPPER_TESTID}>
                    <Controller
                      control={control}
                      name="containerType"
                      render={({ field, fieldState }) => (
                        <Select
                          {...field}
                          instanceId="container-type"
                          options={containerTypes}
                          placeholder="Тип контейнера *"
                          error={fieldState.error?.message}
                        />
                      )}
                      rules={{
                        required: { value: true, message: "Обязательное поле" },
                      }}
                    />
                  </div>

                  {fields.map((field, index) => (
                    <React.Fragment key={field.id}>
                      {isContainerHeightRequired && (
                        <div className="" data-testid={CONTAINER_HEIGHT_SELECT_WRAPPER_TESTID}>
                          <Controller
                            control={control}
                            name={`cargos.${index}.containerHeight`}
                            render={({ field, fieldState }) => (
                              // @ts-ignore
                              <Select
                                {...field}
                                instanceId="container-height"
                                options={containerHeights}
                                placeholder="Высота контейнера *"
                                error={fieldState.error?.message}
                              />
                            )}
                            rules={{
                              required: { value: true, message: "Обязательное поле" },
                            }}
                          />
                        </div>
                      )}

                      {!isEmptyContainer && (
                        <div className="" data-testid={CONTAINER_WEIGHT_SELECT_WRAPPER_TESTID}>
                          <Controller
                            control={control}
                            name={`cargos.${index}.weight`}
                            render={({ field, fieldState }) => (
                              // @ts-ignore
                              <WeightInput
                                {...field}
                                inputStyles="!rounded-xl"
                                placeholder="Вес груза (кг) *"
                                className="w-full"
                                error={fieldState.error?.message}
                              />
                            )}
                            rules={{ required: { value: true, message: "Обязательное поле" } }}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                  {!!howToCarryOptions.length && (
                    <div>
                      <Controller
                        control={control}
                        name="howToCarry"
                        render={({ field, fieldState }) => (
                          <Select
                            {...field}
                            instanceId="how-to-carry-option"
                            options={howToCarryOptions}
                            placeholder="Как везти *"
                            error={fieldState.error?.message}
                          />
                        )}
                        shouldUnregister={true}
                        rules={{
                          required: { value: true, message: "Обязательное поле" },
                        }}
                      />
                    </div>
                  )}
                  {isContainerClassRequired && (
                    <div>
                      <Controller
                        control={control}
                        name="containerClass"
                        render={({ field, fieldState }) => (
                          <Select
                            {...field}
                            instanceId="container-class"
                            options={containerClasses}
                            placeholder="Класс контейнера *"
                            error={fieldState.error?.message}
                          />
                        )}
                        shouldUnregister={true}
                        rules={{
                          required: { value: true, message: "Обязательное поле" },
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </FieldSet>
            <FieldSet legend="Маршрут заказа">
              <div id="route-section-container" className="mt-6 grid grid-cols-1 gap-6 tablet:grid-cols-4">
                <div className="tablet:col-span-2" data-testid={PICKUP_CITY_SELECT_WRAPPER_TESTID}>
                  <Controller
                    control={control}
                    name="pickupCity"
                    render={({ field, fieldState }) => (
                      <Select
                        {...field}
                        instanceId="pickup-city"
                        options={locations}
                        placeholder="Локация получения *"
                        error={fieldState.error?.message}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Обязательное поле" },
                    }}
                  />
                </div>
                <div className="tablet:col-span-2" data-testid={PICKUP_TERMINAL_SELECT_WRAPPER_TESTID}>
                  <Controller
                    control={control}
                    name="pickupTerminal"
                    render={({ field, fieldState }) => (
                      <Select
                        {...field}
                        instanceId="pickup-terminal"
                        options={pickupTerminals}
                        placeholder="Место получения контейнера *"
                        isSearchable
                        isDisabled={!pickupCity}
                        error={fieldState.error?.message}
                        filterOption={createFilter(terminalFilterConfig)}
                        noOptionsMessage={() => "По запросу ничего не найдено"}
                        optionComponent={TerminalOption}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Обязательное поле" },
                    }}
                  />
                </div>
                {isWarehouseAddressRequired && (
                  <div className="tablet:col-span-4" data-testid={WAREHOUSE_ADDRESS_WRAPPER_TESTID}>
                    <Controller
                      control={control}
                      name="warehouseAddress"
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          instanceId="warehouse-address"
                          placeholder="Место доставки контейнера *"
                          fetcher={suggestions.getAddressSuggestions}
                          noOptionsMessage={(v) => (v.inputValue ? "Не удалось найти адрес" : "Начните вводить адрес")}
                          loadingMessage={() => "..."}
                          error={fieldState.error?.message}
                        />
                      )}
                      shouldUnregister={true}
                      rules={{
                        required: { value: true, message: "Обязательное поле" },
                      }}
                    />
                  </div>
                )}
                <div className="hidden tablet:col-span-2 "></div>
                <div className="tablet:col-span-2" data-testid={DELIVERY_CITY_SELECT_WRAPPER_TESTID}>
                  <Controller
                    control={control}
                    name="deliveryCity"
                    render={({ field, fieldState }) => (
                      <Select
                        {...field}
                        instanceId="delivery-city"
                        options={locations}
                        placeholder="Локация сдачи *"
                        error={fieldState.error?.message}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Обязательное поле" },
                    }}
                  />
                </div>
                <div className="tablet:col-span-2" data-testid={DELIVERY_TERMINAL_SELECT_WRAPPER_TESTID}>
                  <Controller
                    control={control}
                    name="deliveryTerminal"
                    render={({ field, fieldState }) => (
                      <Select
                        {...field}
                        instanceId="delivery-terminal"
                        options={deliveryTerminals}
                        placeholder="Место сдачи контейнера *"
                        isDisabled={!deliveryCity}
                        isSearchable
                        error={fieldState.error?.message}
                        filterOption={createFilter(terminalFilterConfig)}
                        noOptionsMessage={() => "По запросу ничего не найдено"}
                        optionComponent={TerminalOption}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Обязательное поле" },
                    }}
                  />
                </div>
              </div>
            </FieldSet>
            <div
              className={clsx("flex flex-col gap-6 desktop:flex-row-reverse", {
                "desktop:justify-end": !tariff,
                "desktop:justify-between": tariff,
              })}
            >
              {!!tariff && (
                <div
                  className=" flex flex-row items-baseline gap-2 desktop:relative desktop:-right-[24rem]"
                  data-testid={TARIFF_CONTAINER_TESTID}
                >
                  <label className="text-smallTitle text-label">Тариф</label>
                  <div className="whitespace-nowrap text-header2 text-primary">
                    {numberWithSpaces(Math.round(tariff) || 0)} ₽
                  </div>
                </div>
              )}

              <div>
                {tariff ? (
                  <IconButton
                    className="!w-fit"
                    variant="contain"
                    size="large"
                    onClick={() =>
                      !authenticated ? router.push("/auth/register/create-account") : router.push(initialDashboardPage)
                    }
                    icon={
                      <div className="h-6 w-6">
                        <Arrow />
                      </div>
                    }
                    iconPlacement="left"
                  >
                    <span className="whitespace-nowrap">Создать заказ</span>
                  </IconButton>
                ) : (
                  <IconButton
                    className="!w-fit"
                    type="submit"
                    variant="contain"
                    size="large"
                    loading={mutation.isLoading}
                    disabled={mutation.isLoading}
                    data-testid={CALCULATE_TARIFF_BUTTON}
                    icon={
                      <div className="h-6 w-6">
                        <CalculatorIcon />
                      </div>
                    }
                    iconPlacement="left"
                  >
                    <span className="whitespace-nowrap">Рассчитать тариф</span>
                  </IconButton>
                )}
              </div>
            </div>
            {mutation.isError && (
              <div className="text-center text-error" data-testid="error-message">
                {mutation.error.errorMessage || "Произошла ошибка, попробуйте повторить"}
              </div>
            )}
          </form>
        </div>
        <img
          src="/images/calculator/full-car.png"
          className="hidden flex-row rounded-r-[40px] desktop:block"
          alt="car"
        ></img>
      </div>
    </Section>
  );
};

export default React.memo(Calculator);
