import React, { useCallback, useEffect, useState } from "react";
import ReactSelect, { components, OptionsOrGroups } from "react-select";
import { useToggle } from "react-use";

import Select from "@/components/Select3";
import { inputActions } from "@/components/Autocomplete2/constants";

const Input = (props: any) => <components.Input className="autocomplete-input" {...props} isHidden={false} />;

const truthy = () => true;

export type OptionType = {
  label: string;
  value: string;
};

type Props = Omit<Parameters<ReactSelect>[0], "inputValue" | "onInputChange"> & {
  error?: string;
  fetcher: (query: string) => Promise<any[]>;
  isDetailedOptions?: boolean;
  addressDeepnessError?: string;
  shallow?: boolean;
  smallFontSize?: boolean;
  required?: boolean;
};

const Autocomplete = React.forwardRef(
  (
    {
      value,
      onChange,
      onFocus,
      fetcher,
      isDetailedOptions,
      addressDeepnessError,
      isDisabled,
      shallow,
      smallFontSize,
      required = true,
      ...props
    }: Props,
    ref,
  ) => {
    const [loading, toggleLoading] = useToggle(false);
    const [showValue, toggleShowValue] = useToggle(true);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<OptionsOrGroups<any, any>[]>([]);
    const [error, setError] = useState(false);
    const [suggestionsValue, setSuggestionsValue] = useState("");
    const [toggle, setToggle] = useState(false);

    const handleInputChange = useCallback(
      (input: any, { action }) => {
        if (action === inputActions.INPUT_BLUR) {
          !value && setInputValue("");
          toggleShowValue(true);

          setToggle(!toggle);
        }
        if (action === inputActions.INPUT_CHANGE || action === inputActions.SET_VALUE) {
          setInputValue(input);
        }
      },
      [value, toggle],
    );

    const handleChange = useCallback(
      (option: any, meta) => {
        onChange && onChange(option, meta);
        setInputValue(option ? option.value : "");
        setSuggestionsValue(option ? option.value : "");

        if (isDetailedOptions) {
          if (option.value.split(",").length < 3) {
            setError(true);
          } else {
            setError(false);
          }
        }
      },
      [onChange],
    );
    const handleFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        toggleShowValue(false);
        if (value) {
          setInputValue((value as OptionType).value);
          setSuggestionsValue((value as OptionType).value);
        }
        onFocus && onFocus(event);
      },
      [value, onFocus],
    );

    useEffect(() => {
      if (value === null) {
        setInputValue("");
        setSuggestionsValue("");
        return;
      }
    }, [value]);
    useEffect(() => {
      if (isDisabled) return;

      toggleLoading(true);
      fetcher(inputValue).then((result) => {
        setOptions(result);
        toggleLoading(false);
      });
    }, [inputValue, isDisabled]);

    useEffect(() => {
      if (suggestionsValue !== inputValue && shallow) {
        setInputValue(suggestionsValue);
      }
    }, [toggle]);

    return (
      <>
        <Select
          inputValue={inputValue}
          onInputChange={handleInputChange}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleFocus}
          filterOption={truthy}
          options={options}
          controlShouldRenderValue={showValue && !!value}
          components={{ Input: Input }}
          isSearchable
          isLoading={loading}
          isDisabled={isDisabled}
          smallFontSize={smallFontSize}
          {...props}
        />
        {error && isDetailedOptions && (
          <p className="text-error"> {addressDeepnessError ? addressDeepnessError : "Укажите адрес, включая дом"}</p>
        )}
      </>
    );
  },
);

export default Autocomplete;
