import clsx from "clsx";

const SingleValue = (props: any) => {
  return (
    <span
      className={clsx(
        "w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-textBodySemi",
        "col-start-1 col-end-3 row-start-1 row-end-2 max-w-full",
      )}
      title={props.selectProps.getOptionLabel(props.data)}
    >
      {props.selectProps.getOptionLabel(props.data)}
    </span>
  );
};

export default SingleValue;
